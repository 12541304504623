<svelte:options tag="search-page" />

<script>
  import { onMount } from "svelte";
  import Utils from "./utils/utils";

  let isLoaded = false;
  let rows = [];
  let pageNumber = 1;
  let pageSize = 5;
  let totalItems = 0;
  let searchQuery = "";
  let pageSizes = [5, 10, 25, 50, 100];
  let noResultsFound = false;

  // let basePath = 'http://localhost:7000'
  // let basePath = 'https://pio-coll-intra.aequaroma.it'
  let basePath = 'https://servizi.aequaroma.it'

  $: pagesCount = Math.ceil(totalItems / pageSize);
  $: firstItemIndex = (pageNumber - 1) * pageSize + 1;
  $: lastItemIndex = Math.min(pageNumber * pageSize, totalItems);

  onMount(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    searchQuery = urlParams.get("search") || "";

    if (searchQuery) {
      await fetchData(pageNumber);
      isLoaded = true;
    } else {
      isLoaded = true;
    }
    isLoaded = true;
  });

  async function fetchData(pageNum) {
    const from = (pageNum - 1) * pageSize;
    try {
      const response = await Utils.request({
        fetch: window.fetch,
        url: `${basePath}/pio/api/public/cms/opensearch/aequa/cms/search`,
        method: "PUT",
        body: {
          from: from,
          size: pageSize,
          _source: ["string"],
          query: {
            multi_match: {
              query: searchQuery,
              fields: ["string"],
            },
          },
        },
      });
      if (response && response.hits && Array.isArray(response.hits.hits)) {
        rows = response.hits.hits.map((hit) => ({
          img: hit._source.image,
          title: hit._source.title,
          url: hit._source.url,
          abstract: hit._source.abstract,
        }));

        totalItems = response.hits.total.value;

        noResultsFound = rows.length === 0; // Update flag based on results
      } else {
        noResultsFound = true; // Set to true if response is not as expected
      }
      isLoaded = true;
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  }

  function changePageSize(newSize) {
    pageSize = newSize;
    pageNumber = 1;
    fetchData(pageNumber);
  }

  function onNextPage() {
    if (pageNumber < pagesCount) {
      pageNumber++;
    }
    fetchData(pageNumber);
  }
  function onPreviousPage() {
    if (pageNumber > 1) {
      pageNumber--;
    }
    fetchData(pageNumber);
  }

  function onFirstPage() {
    if (pageNumber !== 1) {
      pageNumber = 1;
      fetchData(pageNumber);
    }
  }

  function onLastPage() {
    if (pageNumber !== pagesCount) {
      pageNumber = pagesCount;
      fetchData(pageNumber);
    }
  }
</script>

{#if isLoaded}
  {#if rows.length > 0}
    <div
      class="column"
      style="justify-content: start !important; padding: 2em;"
    >
      {#each rows as row}
        <a href={row.url}>
          <div
            class="card row col-12"
            style="margin-bottom: 1.5em; justify-content: between; padding: 1em;"
          >
            <div class="col-12 col-lg-4 my-wrapper-img" style="display: flex;">
              <img
                src={row.img || "/assets/images/default_search_thumbnail.png"}
                alt="Avatar"
                class="my-img"
              />
            </div>
            <div class="container col-12 col-lg-8" style="padding-left: 1em;">
              <h3 class="second-title">{row.title}</h3>
              {#if row.abstract}
                <p class="my-text">{row.abstract}</p>
              {/if}
              <p class="text-muted" style="margin-top: 1em; font-size: 12px;">
                {row.url}
              </p>
            </div>
          </div>
        </a>
      {/each}
    </div>

    <div style="display: flex; justify-content: space-between; margin: 0 50px">
      <div class="text-muted" style="display: flex;">
        Mostrati {firstItemIndex} - {lastItemIndex} su {totalItems} risultati
      </div>

      <div class="col-6" style="display: flex;">
        <span style="margin-right: 10px;">Righe per pagina:</span>
        <select on:change={(e) => changePageSize(e.target.value)}>
          {#each pageSizes as size}
            <option value={size} selected={size === pageSize}>{size}</option>
          {/each}
        </select>
      </div>
    </div>

    <nav>
      <ul class="pagination" style="justify-content: center;">
        <li class="page-item">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-missing-attribute -->
          <a
            class="page-link"
            on:click={onFirstPage}
            class:disabled={pageNumber == 1}>&larr; Primo</a
          >
        </li>
        <li class="page-item">
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <!-- svelte-ignore a11y-missing-attribute -->
          <a
            class="prev page-link"
            on:click={onPreviousPage}
            class:disabled={pageNumber == 1}>&larr; Precedente</a
          >
        </li>
        <li class="page-item">
          <!-- svelte-ignore a11y-missing-attribute -->
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <a
            class="next page-link"
            on:click={onNextPage}
            class:disabled={pageNumber >= pagesCount}>Successivo &rarr;</a
          >
        </li>
        <li class="page-item">
          <!-- svelte-ignore a11y-missing-attribute -->
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <a
            class="page-link"
            on:click={onLastPage}
            class:disabled={pageNumber == pagesCount}>Ultimo &rarr;</a
          >
        </li>
      </ul>
    </nav>
  {:else if noResultsFound && searchQuery}
    <div class="no-results-message">Non ci sono contenuti da mostrare che corrispondono ai criteri di  ricerca</div>
  {:else}
    <div class="no-search-message">Specificare un parametro di ricerca</div>
  {/if}
{/if}

<style>
  .no-results-message,
  .no-search-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; /* Adjust the height as needed */
    text-align: center;
    font-size: 1.2em;
    color: #333; /* Adjust the color as desired */
  }
  .text-muted {
    --bs-text-opacity: 1;
    color: #5a768a !important;
  }
  .second-title {
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 0.2em;
    margin-top: 0;
    color: #272833;
    text-decoration: none;
  }

  .my-text {
    color: rgb(25, 25, 26);
    text-decoration: none;
  }
  .my-img {
    max-width: 300px;
    max-height: 180px;
    justify-content: center;
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .col-2 {
    flex: 0 0 auto;
    width: 16.66666667% !important;
  }

  .col-10 {
    flex: 0 0 auto;
    width: 83.33333333% !important;
  }

  @media (max-width: 992px) {
    .col-12 {
      flex: 0 0 auto;
      width: 100% !important;
    }
  }
  @media (max-width: 992px) {
    .my-wrapper-img {
      justify-content: center;
    }
  }
  @media (min-width: 992px) {
    .my-wrapper-img {
      justify-content: start;
    }
  }

  .card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
  }

  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  /* Add some padding inside the card container */
  .container {
    padding: 2px 16px;
  }
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .row > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }

  @media (min-width: 992px) {
    .col-lg-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-lg-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-lg-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-lg-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-lg-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-lg-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-lg-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-lg-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
  }

  @media (min-width: 992px) {
    .col-lg-3 {
      flex: 0 0 auto;
      width: 25%;
    }
  }

  @media (min-width: 1520px) {
    .my-row {
      flex-wrap: nowrap;
    }
  }

  @media (max-width: 767px) {
    .col-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  }

  .pagination .page-item .page-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    min-width: 2.5rem;
    border-radius: 4px;
    border: none;
    font-size: 0.8888888889rem;
    font-weight: 600;
    background-color: rgba(0, 0, 0, 0);
  }
  @media (min-width: 576px) {
    .pagination .page-item .page-link {
      height: 2.6666666667rem;
      min-width: 2.6666666667rem;
    }
  }

  .page-link {
    padding: 0.375rem 0.75rem;
  }

  .disabled {
    color: #5b6f82 !important;
    cursor: auto !important;
  }

  .page-link {
    position: relative;
    cursor: pointer;
    display: block;
    color: #8e001c;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #b1b1b3;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  a {
    text-decoration: none;
  }

  .pagination .page-item {
    margin-right: 5px;
  }

  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
  }
</style>
