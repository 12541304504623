class Utils {
  async request({ fetch, url, method = 'GET', body = null }) { 
    const headers = {
      "Content-Type": "application/json",
    };
    const options = {
      method: method,
      mode: "cors",
      cache: "no-cache",
      headers,
    };

    if (body && method !== 'GET' && method !== 'HEAD') {
      options.body = JSON.stringify(body);
    }

    try {
      const rs = await fetch(url, options);
      let json;
      if (rs?.status == 302) {
        throw { status: 302, statusText: "Session Expired" };
      }

      if (rs?.status != 204) {
        json = await rs.json();
      }
      return json;
    } catch (error) {
      console.log("Error", error);
      throw error;
    }
  }
}

export default new Utils();